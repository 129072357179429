<template>
	<section>
		<h1>Vorstellung</h1>
		<p>Seit 1991 bin ich hauptberuflich und selbstständig als Klavierstimmer in der Region Vorderpfalz tätig.</p>
		<p>Zu meinem Kundenkreis gehören Musik- und Klavierliebhaber, Pianisten, Musikschulen und -lehrer, Konzertveranstalter, Kultureinrichtungen, Chöre und Musikgruppen, Tonstudios, Theater, usw.</p>
		<p>
			Aus meiner langjährigen Erfahrung heraus, kann ich Ihnen bei allen Fragen rund ums Klavier behilflich sein. Sei es eine Begutachtung, Hilfe beim Transport, Beratung beim Klavierkauf oder -verkauf, Vermittlung einer Reparatur oder einer
			günstigen Oberflächenrestauration, immer können Sie sich gerne meinen fachmännischen Rat einholen.
		</p>
		<img src="@/../public/img/as.gif" alt="Unterschift Andreas Schneider" />
	</section>
</template>

<style lang="scss" scoped>
	img {
		margin-top: 15px;
	}
</style>
